<template>
  <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="itemName"
                      :promises.sync="computedPromises" :formPromises.sync="computedPromises"
                      :detailViewRouteLocation="detailViewRouteLocation">
    <template v-slot:form-fields>
      <slot name="form-fields" />
    </template>
    <template v-slot:buttons-left="slotProps">
      <slot name="buttons-left" v-bind="slotProps"/>
    </template>
    <template v-slot:save-button-content>
      <slot name="save-button-content" />
    </template>
  </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";

export default {
  name: "AvatarDetailEditCard",
  props: {
    promises: Array,
    itemName: {
      type: String,
      required: true,
    },
    detailViewRouteLocation:{
      type: Object,
      required: true
    },
  },

  data() {
    return {
      routeNames,
    }
  },
  components: {
    DetailItemEditCard,
  },
  computed: {
    computedPromises: {
      get() {
        return this.promises
      },
      set(newValue) {
        this.$emit("update:promises", newValue)
      }
    }
  },
}
</script>