<template>
  <div>
    <AvatarDetailEditCard  v-bind="$attrs" v-on="$listeners"
                          :confirmation-message="getConfirmationMessage()"
                          :title="title" :itemId="id"
                          :detailViewRouteLocation="detailViewRoute"
                          :update-store-action="deleteAvatar"
                          :upload-avatar-action="updateStoreActionName"
                          :overrideDisableButton="!image" :hideSaveButton="hideSaveButton">
      <template v-slot:form-fields>
        <v-item-group>
          <v-row v-if="image">
            <v-col cols="6" md="4" lg="3" xl="2">
              <v-item :value="image">
                <v-img :key="imageKey" :src="image" class="text-right" contain aspect-ratio="1">
                </v-img>
              </v-item>
            </v-col>
          </v-row>
          <v-row v-else class="d-flex flex-column flex-grow-1 w-100">
            <v-icon class="align-self-center flex-grow-1" size="110px">mdi-image-off-outline</v-icon>
            <div class="align-self-center">Er is geen avatar beschikbaar</div>
          </v-row>
        </v-item-group>
      </template>
      <template v-slot:buttons-left="slotProps">
        <v-col cols="12" :sm="!getSaveButtonContent ? 12: 'auto'" class="d-flex align-center flex-grow-1">
          <v-row dense>
            <v-col  :sm="6" class="d-flex align-center">
              <v-file-input :rules="fileRules" solo dense counter prepend-icon="" counter-string=""
                            prepend-inner-icon="$file" clearable class="mr-1" v-model="imageToUpload" accept="image/*"></v-file-input>
            </v-col>
            <v-col align="end">
              <v-btn class="secondary" @click="uploadLogo" :disabled="!slotProps.valid"><slot name="save-button-content">Opslaan</slot></v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-slot:save-button-content>
        Verwijder
      </template>
    </AvatarDetailEditCard>
  </div>
</template>

<script>
import routeNames from "@/router/RouteNames";
import AvatarDetailEditCard from "@/components/shared/AvatarDetailEditCard";
import {getFileSizeRules, getRequiredRules} from "@/shared/utils/inputRulesUtils";


export default {
  name: "AvatarDetailEditForm",
  components: {
    AvatarDetailEditCard,
  },
  props: {
    confirmationMessage: {
      type: String,
      default: "Avatar werd succesvol gewijzigd"
    },
    title: {
      type: String,
      default: "Bewerk avatar"
    },
    detailViewRoute:{
      type: Object,
      required: true
    },
    updateStoreActionName:{
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    hideSaveButton:{
      type: Boolean,
      required: false,
      default: false
    },
    retrieveAvatar:{
      type:String,
      required:true
    },
    deleteAvatar:{
      type: String,
      required: false,
      default: ""
    },
    SASKey: null,
  },
  data() {
    return {
      routeNames: routeNames,
      imageToUpload: null,
      icon: "mdi-close",
      imageKey:Date.now(),
    }
  },
  computed: {
    getSaveButtonContent() {
      return 'Verwijder'
    },
    image(){
      let image = this.$store.getters[this.retrieveAvatar](this.id)
      if (image && this.SASKey) {
        return image + '&' + this.SASKey
      } else {
        return null
      }
    },
    fileRules(){
      return [...getRequiredRules("Afbeelding moet worden ingevuld om te kunnen uploaden"), ...getFileSizeRules(100000,  "Afbeelding mag maximaal 100 KB groot zijn")]
    }
  },
  methods: {
    uploadLogo(){
      this.option = "upload"
      this.$store.dispatch(this.updateStoreActionName, {id: this.id, image: this.imageToUpload})
          .then(() => {
            this.$store.dispatch("setSuccessMessage", { message: this.confirmationMessage})
            this.imageKey = Date.now()
          })
      this.imageToUpload = null
      this.option= 'delete'
    },
    getConfirmationMessage(){
      if (this.option === 'delete'){
        return "Avatar werd succesvol verwijderd"
      }
      return this.confirmationMessage
    }
  }
}
</script>
<style lang="scss" scoped>
// remove the darkner background effect of vuetify
::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__image {
  background: none
}
</style>